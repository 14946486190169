.descripcion-precios{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 4vh;
}

.price-productos {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.price-productos span {
  font-size: 18px;
  font-weight: 1000;
  color: #008000;
}

/* Estilos para la bola verde parpadeante */
.blink-dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.5s ease-in-out;
}

.green-blink {
    background-color: green;
}

