.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Fondo oscuro semitransparente */
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
    /* Asegura que el contenido del popup esté por encima del fondo oscuro */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    max-width: 500px;
    /* Máximo ancho del popup */
    position: relative;
}

.popup-content h2 {
    text-align: center;
}

.popup-content h2 span {
    color: #1a98ff;
    text-transform: uppercase;
    font-style: italic;
    font-size: 1000;
}

.popup-content form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-content img {
    width: 50%;
    object-fit: contain;
}

.popup-content form .codigo-verificacion {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.popup-content form input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 1000;
    border-radius: 8px;
    border: 3px solid #1a98ff;
    outline: none;
}

.codigo-verificacion {
    width: 60%;
}

.popup-content svg {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.popup-content form button {
    background-color: #1a98ff;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 10px;
    cursor: pointer;
    margin-top: 10px;
}

/* Estilos adicionales para dispositivos móviles */
@media screen and (max-width: 768px) {
    .popup-content {
        width: 90%;
        max-width: 400px;
    }

    .popup-content form input {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }
}